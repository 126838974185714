<template>
    <div class="page-service-provider-products">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <app-loader v-if="loading" fixed-on="desktop" />

                    <app-error v-model="errors.show" :message="errors.message" />

                    <app-table
                        class="products-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="rows"

                        :clickable="true"

                        @row-click="openDetails"
                        @row-click-mobile="openDetails"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,
        appTable,
        appPagination,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'Name',
                    order: 'asc',
                },
            },

            sort: [
                { field: 'Name',        title: 'Name'         },
                { field: 'ProductType', title: 'Product type' },
            ],

            cols: [
                { key: 'Name',        title: 'Name',         sort: { field: 'Name'        }, highlight: true, multiline: true },
                { key: 'Description', title: 'Description',                                                   multiline: true },
                { key: 'ProductType', title: 'Product type', sort: { field: 'ProductType' }                                   },
            ],

            products: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getProducts()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getProducts()
        },

        onPageChange(page) {
            this.getProducts(page)
        },

        getProducts(page) {
            this.loading = true

            const params = {
                SPID: this.current_spid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_product_catalog/FindProductCatalogEntriesWithDetailsPaginated', params)
                .then(response => {
                    if (!response.ProductCatalogEntryWithDetailsItems || !response.PageInfo) {
                        return Promise.reject(response)
                    }

                    this.products = response.ProductCatalogEntryWithDetailsItems

                    this.pagination.page = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            query: this.$route.query,
                            replace: true
                        })
                    }

                    this.errors.show = false
                    this.errors.message = ''

                    this.loading = false
                })
                .catch(error => {
                    this.products = []
                    this.loading = false
                    if (error.PageInfo && error.ProductCatalogEntryWithDetailsItems === null) {
                        this.errors.show = true
                        this.errors.message = 'No products are currently assigned to you - Please contact your account manager'
                    } else if (error.response && error.response.status == 404) {
                        return
                    } else {
                        this.errors.show = true
                        this.errors.message = errMessage(error)
                    }
                })
        },

        openDetails(row) {
            /**
             * @todo
             * #21318
             */
            this.$router.push({ name: 'service-provider-product', params: { uuid: row.uuid } })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        rows() {
            return this.products.map(({ ProductCatalogEntry }) => ({
                uuid: ProductCatalogEntry.UUID,

                Name: ProductCatalogEntry.Name ? ProductCatalogEntry.Name : '?',
                Description: ProductCatalogEntry.Description ? ProductCatalogEntry.Description : '?',
                ProductType: ProductCatalogEntry.ProductType ? ProductCatalogEntry.ProductType : '?',
            }))
        },
    },
}
</script>

<style lang="scss">
.page-service-provider-products {
    padding-bottom: 80px;

    .app-error {
        margin-bottom: 24px;
    }

    .products-table {
        @include table-cols-width((150px, 350px, 120px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-service-provider-products {
        .products-table {
            @include table-cols-width((100px, 200px, 100px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-service-provider-products {
        padding-bottom: 64px;

        .products-table {
            @include table-cols-width-mobile((70px, 205px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>